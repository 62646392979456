/**
 * @fileoverview gRPC-Web generated client stub for grass.mower.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.26.1
// source: apis/mower/v1/api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as apis_mower_v1_api_pb from '../../../apis/mower/v1/api_pb'; // proto import: "apis/mower/v1/api.proto"


export class MowerAPIClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetPrescriptions = new grpcWeb.MethodDescriptor(
    '/grass.mower.v1.MowerAPI/GetPrescriptions',
    grpcWeb.MethodType.UNARY,
    apis_mower_v1_api_pb.GetPrescriptionsRequest,
    apis_mower_v1_api_pb.GetPrescriptionsResponse,
    (request: apis_mower_v1_api_pb.GetPrescriptionsRequest) => {
      return request.serializeBinary();
    },
    apis_mower_v1_api_pb.GetPrescriptionsResponse.deserializeBinary
  );

  getPrescriptions(
    request: apis_mower_v1_api_pb.GetPrescriptionsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_mower_v1_api_pb.GetPrescriptionsResponse>;

  getPrescriptions(
    request: apis_mower_v1_api_pb.GetPrescriptionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_mower_v1_api_pb.GetPrescriptionsResponse) => void): grpcWeb.ClientReadableStream<apis_mower_v1_api_pb.GetPrescriptionsResponse>;

  getPrescriptions(
    request: apis_mower_v1_api_pb.GetPrescriptionsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_mower_v1_api_pb.GetPrescriptionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.mower.v1.MowerAPI/GetPrescriptions',
        request,
        metadata || {},
        this.methodDescriptorGetPrescriptions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.mower.v1.MowerAPI/GetPrescriptions',
    request,
    metadata || {},
    this.methodDescriptorGetPrescriptions);
  }

}

export class APIClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorPrescription = new grpcWeb.MethodDescriptor(
    '/grass.mower.v1.API/Prescription',
    grpcWeb.MethodType.UNARY,
    apis_mower_v1_api_pb.PrescriptionRequest,
    apis_mower_v1_api_pb.PrescriptionResponse,
    (request: apis_mower_v1_api_pb.PrescriptionRequest) => {
      return request.serializeBinary();
    },
    apis_mower_v1_api_pb.PrescriptionResponse.deserializeBinary
  );

  prescription(
    request: apis_mower_v1_api_pb.PrescriptionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_mower_v1_api_pb.PrescriptionResponse>;

  prescription(
    request: apis_mower_v1_api_pb.PrescriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_mower_v1_api_pb.PrescriptionResponse) => void): grpcWeb.ClientReadableStream<apis_mower_v1_api_pb.PrescriptionResponse>;

  prescription(
    request: apis_mower_v1_api_pb.PrescriptionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_mower_v1_api_pb.PrescriptionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.mower.v1.API/Prescription',
        request,
        metadata || {},
        this.methodDescriptorPrescription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.mower.v1.API/Prescription',
    request,
    metadata || {},
    this.methodDescriptorPrescription);
  }

}

