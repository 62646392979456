/**
 * @fileoverview gRPC-Web generated client stub for grass.demos.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.26.1
// source: apis/demos/v1/api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb'; // proto import: "google/protobuf/empty.proto"
import * as apis_demos_v1_api_pb from '../../../apis/demos/v1/api_pb'; // proto import: "apis/demos/v1/api.proto"


export class DemosAPIClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorMe = new grpcWeb.MethodDescriptor(
    '/grass.demos.v1.DemosAPI/Me',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    apis_demos_v1_api_pb.MeResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    apis_demos_v1_api_pb.MeResponse.deserializeBinary
  );

  me(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<apis_demos_v1_api_pb.MeResponse>;

  me(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.MeResponse) => void): grpcWeb.ClientReadableStream<apis_demos_v1_api_pb.MeResponse>;

  me(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.MeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.demos.v1.DemosAPI/Me',
        request,
        metadata || {},
        this.methodDescriptorMe,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.demos.v1.DemosAPI/Me',
    request,
    metadata || {},
    this.methodDescriptorMe);
  }

  methodDescriptorGetSchema = new grpcWeb.MethodDescriptor(
    '/grass.demos.v1.DemosAPI/GetSchema',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    apis_demos_v1_api_pb.GetSchemaResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    apis_demos_v1_api_pb.GetSchemaResponse.deserializeBinary
  );

  getSchema(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<apis_demos_v1_api_pb.GetSchemaResponse>;

  getSchema(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.GetSchemaResponse) => void): grpcWeb.ClientReadableStream<apis_demos_v1_api_pb.GetSchemaResponse>;

  getSchema(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.GetSchemaResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.demos.v1.DemosAPI/GetSchema',
        request,
        metadata || {},
        this.methodDescriptorGetSchema,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.demos.v1.DemosAPI/GetSchema',
    request,
    metadata || {},
    this.methodDescriptorGetSchema);
  }

  methodDescriptorUpdateSchema = new grpcWeb.MethodDescriptor(
    '/grass.demos.v1.DemosAPI/UpdateSchema',
    grpcWeb.MethodType.UNARY,
    apis_demos_v1_api_pb.UpdateSchemaRequest,
    apis_demos_v1_api_pb.UpdateSchemaResponse,
    (request: apis_demos_v1_api_pb.UpdateSchemaRequest) => {
      return request.serializeBinary();
    },
    apis_demos_v1_api_pb.UpdateSchemaResponse.deserializeBinary
  );

  updateSchema(
    request: apis_demos_v1_api_pb.UpdateSchemaRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_demos_v1_api_pb.UpdateSchemaResponse>;

  updateSchema(
    request: apis_demos_v1_api_pb.UpdateSchemaRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.UpdateSchemaResponse) => void): grpcWeb.ClientReadableStream<apis_demos_v1_api_pb.UpdateSchemaResponse>;

  updateSchema(
    request: apis_demos_v1_api_pb.UpdateSchemaRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.UpdateSchemaResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.demos.v1.DemosAPI/UpdateSchema',
        request,
        metadata || {},
        this.methodDescriptorUpdateSchema,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.demos.v1.DemosAPI/UpdateSchema',
    request,
    metadata || {},
    this.methodDescriptorUpdateSchema);
  }

  methodDescriptorGetSettings = new grpcWeb.MethodDescriptor(
    '/grass.demos.v1.DemosAPI/GetSettings',
    grpcWeb.MethodType.UNARY,
    apis_demos_v1_api_pb.GetSettingsRequest,
    apis_demos_v1_api_pb.GetSettingsResponse,
    (request: apis_demos_v1_api_pb.GetSettingsRequest) => {
      return request.serializeBinary();
    },
    apis_demos_v1_api_pb.GetSettingsResponse.deserializeBinary
  );

  getSettings(
    request: apis_demos_v1_api_pb.GetSettingsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_demos_v1_api_pb.GetSettingsResponse>;

  getSettings(
    request: apis_demos_v1_api_pb.GetSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.GetSettingsResponse) => void): grpcWeb.ClientReadableStream<apis_demos_v1_api_pb.GetSettingsResponse>;

  getSettings(
    request: apis_demos_v1_api_pb.GetSettingsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.GetSettingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.demos.v1.DemosAPI/GetSettings',
        request,
        metadata || {},
        this.methodDescriptorGetSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.demos.v1.DemosAPI/GetSettings',
    request,
    metadata || {},
    this.methodDescriptorGetSettings);
  }

  methodDescriptorUpdateSettings = new grpcWeb.MethodDescriptor(
    '/grass.demos.v1.DemosAPI/UpdateSettings',
    grpcWeb.MethodType.UNARY,
    apis_demos_v1_api_pb.UpdateSettingsRequest,
    apis_demos_v1_api_pb.UpdateSettingsResponse,
    (request: apis_demos_v1_api_pb.UpdateSettingsRequest) => {
      return request.serializeBinary();
    },
    apis_demos_v1_api_pb.UpdateSettingsResponse.deserializeBinary
  );

  updateSettings(
    request: apis_demos_v1_api_pb.UpdateSettingsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_demos_v1_api_pb.UpdateSettingsResponse>;

  updateSettings(
    request: apis_demos_v1_api_pb.UpdateSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.UpdateSettingsResponse) => void): grpcWeb.ClientReadableStream<apis_demos_v1_api_pb.UpdateSettingsResponse>;

  updateSettings(
    request: apis_demos_v1_api_pb.UpdateSettingsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.UpdateSettingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.demos.v1.DemosAPI/UpdateSettings',
        request,
        metadata || {},
        this.methodDescriptorUpdateSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.demos.v1.DemosAPI/UpdateSettings',
    request,
    metadata || {},
    this.methodDescriptorUpdateSettings);
  }

  methodDescriptorDeleteSettings = new grpcWeb.MethodDescriptor(
    '/grass.demos.v1.DemosAPI/DeleteSettings',
    grpcWeb.MethodType.UNARY,
    apis_demos_v1_api_pb.DeleteSettingsRequest,
    apis_demos_v1_api_pb.DeleteSettingsResponse,
    (request: apis_demos_v1_api_pb.DeleteSettingsRequest) => {
      return request.serializeBinary();
    },
    apis_demos_v1_api_pb.DeleteSettingsResponse.deserializeBinary
  );

  deleteSettings(
    request: apis_demos_v1_api_pb.DeleteSettingsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_demos_v1_api_pb.DeleteSettingsResponse>;

  deleteSettings(
    request: apis_demos_v1_api_pb.DeleteSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.DeleteSettingsResponse) => void): grpcWeb.ClientReadableStream<apis_demos_v1_api_pb.DeleteSettingsResponse>;

  deleteSettings(
    request: apis_demos_v1_api_pb.DeleteSettingsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.DeleteSettingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.demos.v1.DemosAPI/DeleteSettings',
        request,
        metadata || {},
        this.methodDescriptorDeleteSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.demos.v1.DemosAPI/DeleteSettings',
    request,
    metadata || {},
    this.methodDescriptorDeleteSettings);
  }

  methodDescriptorUserPreferences = new grpcWeb.MethodDescriptor(
    '/grass.demos.v1.DemosAPI/UserPreferences',
    grpcWeb.MethodType.UNARY,
    apis_demos_v1_api_pb.UserPreferencesRequest,
    apis_demos_v1_api_pb.UserPreferencesResponse,
    (request: apis_demos_v1_api_pb.UserPreferencesRequest) => {
      return request.serializeBinary();
    },
    apis_demos_v1_api_pb.UserPreferencesResponse.deserializeBinary
  );

  userPreferences(
    request: apis_demos_v1_api_pb.UserPreferencesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_demos_v1_api_pb.UserPreferencesResponse>;

  userPreferences(
    request: apis_demos_v1_api_pb.UserPreferencesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.UserPreferencesResponse) => void): grpcWeb.ClientReadableStream<apis_demos_v1_api_pb.UserPreferencesResponse>;

  userPreferences(
    request: apis_demos_v1_api_pb.UserPreferencesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.UserPreferencesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.demos.v1.DemosAPI/UserPreferences',
        request,
        metadata || {},
        this.methodDescriptorUserPreferences,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.demos.v1.DemosAPI/UserPreferences',
    request,
    metadata || {},
    this.methodDescriptorUserPreferences);
  }

  methodDescriptorUpdateUserPreferences = new grpcWeb.MethodDescriptor(
    '/grass.demos.v1.DemosAPI/UpdateUserPreferences',
    grpcWeb.MethodType.UNARY,
    apis_demos_v1_api_pb.UpdateUserPreferencesRequest,
    apis_demos_v1_api_pb.UpdateUserPreferencesResponse,
    (request: apis_demos_v1_api_pb.UpdateUserPreferencesRequest) => {
      return request.serializeBinary();
    },
    apis_demos_v1_api_pb.UpdateUserPreferencesResponse.deserializeBinary
  );

  updateUserPreferences(
    request: apis_demos_v1_api_pb.UpdateUserPreferencesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_demos_v1_api_pb.UpdateUserPreferencesResponse>;

  updateUserPreferences(
    request: apis_demos_v1_api_pb.UpdateUserPreferencesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.UpdateUserPreferencesResponse) => void): grpcWeb.ClientReadableStream<apis_demos_v1_api_pb.UpdateUserPreferencesResponse>;

  updateUserPreferences(
    request: apis_demos_v1_api_pb.UpdateUserPreferencesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.UpdateUserPreferencesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.demos.v1.DemosAPI/UpdateUserPreferences',
        request,
        metadata || {},
        this.methodDescriptorUpdateUserPreferences,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.demos.v1.DemosAPI/UpdateUserPreferences',
    request,
    metadata || {},
    this.methodDescriptorUpdateUserPreferences);
  }

  methodDescriptorResetUserPreferences = new grpcWeb.MethodDescriptor(
    '/grass.demos.v1.DemosAPI/ResetUserPreferences',
    grpcWeb.MethodType.UNARY,
    apis_demos_v1_api_pb.ResetUserPreferencesRequest,
    apis_demos_v1_api_pb.ResetUserPreferencesResponse,
    (request: apis_demos_v1_api_pb.ResetUserPreferencesRequest) => {
      return request.serializeBinary();
    },
    apis_demos_v1_api_pb.ResetUserPreferencesResponse.deserializeBinary
  );

  resetUserPreferences(
    request: apis_demos_v1_api_pb.ResetUserPreferencesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_demos_v1_api_pb.ResetUserPreferencesResponse>;

  resetUserPreferences(
    request: apis_demos_v1_api_pb.ResetUserPreferencesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.ResetUserPreferencesResponse) => void): grpcWeb.ClientReadableStream<apis_demos_v1_api_pb.ResetUserPreferencesResponse>;

  resetUserPreferences(
    request: apis_demos_v1_api_pb.ResetUserPreferencesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.ResetUserPreferencesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.demos.v1.DemosAPI/ResetUserPreferences',
        request,
        metadata || {},
        this.methodDescriptorResetUserPreferences,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.demos.v1.DemosAPI/ResetUserPreferences',
    request,
    metadata || {},
    this.methodDescriptorResetUserPreferences);
  }

}

export class APIClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorPreferences = new grpcWeb.MethodDescriptor(
    '/grass.demos.v1.API/Preferences',
    grpcWeb.MethodType.UNARY,
    apis_demos_v1_api_pb.PreferencesRequest,
    apis_demos_v1_api_pb.PreferencesResponse,
    (request: apis_demos_v1_api_pb.PreferencesRequest) => {
      return request.serializeBinary();
    },
    apis_demos_v1_api_pb.PreferencesResponse.deserializeBinary
  );

  preferences(
    request: apis_demos_v1_api_pb.PreferencesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_demos_v1_api_pb.PreferencesResponse>;

  preferences(
    request: apis_demos_v1_api_pb.PreferencesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.PreferencesResponse) => void): grpcWeb.ClientReadableStream<apis_demos_v1_api_pb.PreferencesResponse>;

  preferences(
    request: apis_demos_v1_api_pb.PreferencesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.PreferencesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.demos.v1.API/Preferences',
        request,
        metadata || {},
        this.methodDescriptorPreferences,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.demos.v1.API/Preferences',
    request,
    metadata || {},
    this.methodDescriptorPreferences);
  }

  methodDescriptorUpdatePreferences = new grpcWeb.MethodDescriptor(
    '/grass.demos.v1.API/UpdatePreferences',
    grpcWeb.MethodType.UNARY,
    apis_demos_v1_api_pb.UpdatePreferencesRequest,
    apis_demos_v1_api_pb.UpdatePreferencesResponse,
    (request: apis_demos_v1_api_pb.UpdatePreferencesRequest) => {
      return request.serializeBinary();
    },
    apis_demos_v1_api_pb.UpdatePreferencesResponse.deserializeBinary
  );

  updatePreferences(
    request: apis_demos_v1_api_pb.UpdatePreferencesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_demos_v1_api_pb.UpdatePreferencesResponse>;

  updatePreferences(
    request: apis_demos_v1_api_pb.UpdatePreferencesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.UpdatePreferencesResponse) => void): grpcWeb.ClientReadableStream<apis_demos_v1_api_pb.UpdatePreferencesResponse>;

  updatePreferences(
    request: apis_demos_v1_api_pb.UpdatePreferencesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.UpdatePreferencesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.demos.v1.API/UpdatePreferences',
        request,
        metadata || {},
        this.methodDescriptorUpdatePreferences,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.demos.v1.API/UpdatePreferences',
    request,
    metadata || {},
    this.methodDescriptorUpdatePreferences);
  }

  methodDescriptorFeatures = new grpcWeb.MethodDescriptor(
    '/grass.demos.v1.API/Features',
    grpcWeb.MethodType.UNARY,
    apis_demos_v1_api_pb.FeaturesRequest,
    apis_demos_v1_api_pb.FeaturesResponse,
    (request: apis_demos_v1_api_pb.FeaturesRequest) => {
      return request.serializeBinary();
    },
    apis_demos_v1_api_pb.FeaturesResponse.deserializeBinary
  );

  features(
    request: apis_demos_v1_api_pb.FeaturesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_demos_v1_api_pb.FeaturesResponse>;

  features(
    request: apis_demos_v1_api_pb.FeaturesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.FeaturesResponse) => void): grpcWeb.ClientReadableStream<apis_demos_v1_api_pb.FeaturesResponse>;

  features(
    request: apis_demos_v1_api_pb.FeaturesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_demos_v1_api_pb.FeaturesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.demos.v1.API/Features',
        request,
        metadata || {},
        this.methodDescriptorFeatures,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.demos.v1.API/Features',
    request,
    metadata || {},
    this.methodDescriptorFeatures);
  }

}

