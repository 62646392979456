import styled from "@emotion/styled";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useFieldsScreenVM } from "@screens/FieldsScreen/FieldsScreenVM";
import { useLang } from "@src/hooks/useLang";
import { useNavigate } from "react-router-dom";
import { Column, Row } from "@src/components/Flex";
import Text from "@components/Text";
import SizedBox from "@components/SizedBox";
import Button from "@components/Button";
import { ReactComponent as MinimalizeIcon } from "@assets/icons/minimalize.svg";
import { ReactComponent as ExpandIcon } from "@assets/icons/expand.svg";
import { ReactComponent as DownloadIcon } from "@assets/icons/download.svg";
import { ReactComponent as FieldIcon } from "@assets/icons/map.svg";
import useOnClickOutside from "@src/hooks/useOnClickOutside";
import LeftBarSettingsMenuItem from "@components/Layout/LeftBar/LeftBarSettingsMenuItem";
// @ts-ignore
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BN from "@src/utils/BN";
import { useStores } from "@stores";

interface IProps { }

const Root = styled.div<{ width?: number }>`
  position: absolute;
  left: 24px;
  right: 16px;
  bottom: 16px;
  background: #ffffff;
  border-radius: 6px;
  padding: 16px;
  box-sizing: border-box;
  z-index: 11;
`;

const Title = styled(Text)`
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #494c5b;
  white-space: nowrap;
`;

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: fit-content;
  position: absolute;
  top: 16px;
  right: 16px;
  & > :first-of-type {
    margin-right: 8px;
  }
`;

const ControlButton = styled(Button)`
  height: 24px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const Body = styled(Column)`
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
  &,
  th,
  td {
    padding: 7px 12px;
    border: 1px solid #ebeeef;
    border-collapse: collapse;
  }
`;

const Tooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  transition: 0.4s;
  overflow: hidden;
  //height: 92px;
  top: 0;
  background: #ffffff;
  box-shadow: 0 2px 20px rgba(78, 78, 81, 0.2);
  border-radius: 6px 6px 6px 0;
`;


const formatDownloadURL = (field_id: string, field_name: string | null, format: string): string => {
  const baseURL = `https://at.greengrowth.tech/api/mower/v1/${field_id}/yield`;
  const q = new URLSearchParams();

  q.append("format", format);
  if (field_name) {
    q.append("field_name", field_name.replaceAll(" ", "_"));
  }

  return baseURL + "?" + q.toString();
};

const MapYieldStatistics: React.FC<IProps> = () => {
  const vm = useFieldsScreenVM();
  const lang = useLang();
  const { settingsStore } = useStores();
  const navigate = useNavigate();

  const stats =
    vm.selectedFieldId != null
      ? vm.heatmapStatistics[String(vm.selectedFieldId)]
      : undefined;

  const ref = React.useRef(null);
  const [expanded, setExpanded] = useState(true);
  const [tooltipOpened, setTooltipOpened] = useState(false);

  useOnClickOutside(ref, () => setTooltipOpened(false));

  const id = vm.selectedField?.get("id");
  const name = vm.selectedField?.get("name");

  const area = vm.selectedField?.get("area");

  const cropId = vm.selectedField?.get("culture_id");
  const crop = vm.crops.find(({ id }) => id === cropId);

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const file = [
      {
        [lang.fields.statsCropTitle]: crop?.name ?? "",
        [lang.fields.statsAvgTitle]: stats?.avg ?? "",
        [lang.fields.statsTotalTitle]: stats?.total ?? "",
      },
    ];
    const ws = XLSX.utils.json_to_sheet(file);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, (name ?? "fieldData") + fileExtension);
  };

  return vm.selectedFieldView === "yield" && stats ? (
    <Root>
      <ControlsWrapper ref={ref}>
        <Tooltip
          style={{ 
            right: "0.75em",
            top: tooltipOpened ? "-11em": 0,
            width: tooltipOpened ? 208: 0,
            height: tooltipOpened ? "11em" : 0 
          }}
        >
          {[
            { name: "Shapefile", format: "shp" },
            { name: "KML", format: "kml" },
            { name: "GeoJSON", format: "geojson" },
          ].map((item) => {
            return (
              <LeftBarSettingsMenuItem
                key={item.format}
                title={item.name}
                onClick={() => {
                  setTooltipOpened(false);
                  const link = document.createElement("a");
                  link.href = formatDownloadURL(id, name, item.format);
                  link.click();
                }}
              />
            );
          })}
          <LeftBarSettingsMenuItem
            title="Excel"
            onClick={() => {
              exportToCSV();
              setTooltipOpened(false);
            }}
          />
        </Tooltip>
        {settingsStore.features.prescription_maps && (
          <ControlButton kind="secondary" onClick={() => {
            navigate("/prescription/" + id, { state: { field_name: name } });
          }}>
            <FieldIcon />
          </ControlButton>
        )}
        {expanded ? (
          <ControlButton kind="secondary" onClick={() => setExpanded(false)}>
            <MinimalizeIcon />
          </ControlButton>
        ) : (
          <ControlButton kind="secondary" onClick={() => setExpanded(true)}>
            <ExpandIcon />
          </ControlButton>
        )}

        <ControlButton kind="secondary" onClick={() => {
          setTooltipOpened(!tooltipOpened);
        }}>
          <DownloadIcon />
        </ControlButton>
      </ControlsWrapper>
      <Row mainAxisSize="fit-content">
        {name && (
          <>
            <Title>{name}</Title>
            <Title style={{ color: "rgb(204, 213, 216)" }}>&nbsp;•&nbsp;</Title>
          </>
        )}
        {area && (
          <>
            <Title>{area}</Title>
            <Title style={{ color: "rgb(204, 213, 216)" }}>&nbsp;•&nbsp;</Title>
          </>
        )}
        <Title>{crop?.name}</Title>
      </Row>
      {expanded && (
        <Body>
          <SizedBox height={24} />
          <Table>
            <thead>
              <tr>
                <th>
                  <Text color="#494C5B" type="heading" size={14}>
                    {lang.fields.statsCropTitle}
                  </Text>
                </th>
                <th>
                  <Text color="#494C5B" type="heading" size={14}>
                    {lang.fields.statsAvgTitle}
                  </Text>
                </th>
                <th>
                  <Text color="#494C5B" type="heading" size={14}>
                    {lang.fields.statsTotalTitle}
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <Text color="#494C5B" type="heading" size={14}>
                    {crop?.name}
                  </Text>
                </th>
                <th>
                  <Text color="#494C5B" type="heading" size={14}>
                    {stats?.avg != null && new BN(stats?.avg).toFormat(3)}
                  </Text>
                </th>
                <th>
                  <Text color="#494C5B" type="heading" size={14}>
                    {stats?.total != null && new BN(stats?.total).toFormat(3)}
                  </Text>
                </th>
              </tr>
            </tbody>
          </Table>
        </Body>
      )}
    </Root>
  ) : null;
};
export default observer(MapYieldStatistics);
