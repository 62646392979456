import React from "react";

import { toast } from "react-toastify";
import moment from "moment";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import mapboxgl from 'mapbox-gl';
import { FeatureCollection, Point, Position, BBox } from "geojson";

import DatePicker from "@components/DatePicker";
import DeviceWindow from "@screens/TracksScreen/DeviceWindow";
import DevicesMenu from "@screens/TracksScreen/DevicesMenu";
import Dropdown from "@components/MapDropdown";
import LeftBar from "@components/Layout/LeftBar";
import Map, { Source, Layer, Popup } from "@components/Map";
import Spinner from "@components/Spinner";
import { ContextProvider, useContext } from "@screens/TracksScreen/Context";
import { AttachedDevice, deviceStatistics, DeviceStatisticsResponse } from "@services/devicesService";
import { pageView, click } from "@src/utils/analytics";
import { sensorFeatures, speedFeatures } from "@screens/TracksScreen/utils";
import { useLang } from "@src/hooks/useLang";

////////////////////////////////////////////////////////////////////////////////
// STYLES
////////////////////////////////////////////////////////////////////////////////

const StyleOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  background: rgba(256, 256, 256, 0.5);
  z-index: 10;
`;

// TODO(nk2ge5k): why do we need to declare this for every single screen?
const StyleSection = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: stretch;
  overflow-y: hidden;
`;

const StyleMap = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
`;

const emptyCollection: FeatureCollection = {
  type: "FeatureCollection",
  features: [],
};

////////////////////////////////////////////////////////////////////////////////
// UTILITY FUNCTIONS
////////////////////////////////////////////////////////////////////////////////

// userDevicesToPoints returns GeoJSON which can be used for displaying
// last location of the device on the map.
function userDevicesToPoints(devices: AttachedDevice[]): FeatureCollection<Point> {
  return {
    type: "FeatureCollection",
    features: devices
      .filter((device) => {
        return device.getLastPositionList().length === 2;
      })
      .map((device) => {
        let fill = "rgb(209, 213, 219)";

        const last_seen = device.getLastSeenAt();
        if (last_seen !== undefined) {
          const m = moment(last_seen.toDate());
          if (m.isValid()) {
            const hours = m.diff(moment(), "h");
            if (hours <= 1) {
              fill = "rgb(30, 166, 114)";
            } else if (hours <= 24) {
              fill = "rgb(253, 224, 71)";
            } else {
              fill = "rgb(209, 213, 219)";
            }
          }
        }

        return {
          type: "Feature",
          properties: {
            name: device.getName(),
            fill: fill,
          },
          geometry: {
            type: "Point",
            coordinates: device.getLastPositionList() as Position,
          }
        };
      }),
  };
}

const SENSOR_LAYER = "sensor";
const SPEED_LAYER = "speed";
const FIELDS_LAYER = "fields";

function updateFieldsLayers(map: mapboxgl.Map, data: FeatureCollection) {
  const source = map.getSource(FIELDS_LAYER) as mapboxgl.GeoJSONSource;
  source.setData(data);
}

// updateMap updates map with device statistics data.
async function updateMap(
  map: mapboxgl.Map,
  statistics: DeviceStatisticsResponse,
  use_full_screen: boolean,
  width: number,
  delay: number,
  from?: Date,
  to?: Date,
) {
  let bbox: BBox | undefined = undefined;
  {
    const line = sensorFeatures(statistics, width, delay, from, to);
    const source = map.getSource(SENSOR_LAYER) as mapboxgl.GeoJSONSource;
    source.setData(line);
    bbox = line.bbox;
  }
  {
    const line = speedFeatures(statistics, from, to);
    const source = map.getSource(SPEED_LAYER) as mapboxgl.GeoJSONSource;
    source.setData(line);
    if (!bbox) {
      bbox = line.bbox;
    }
  }

  if (bbox) {
    const camera = map.cameraForBounds(bbox as [number, number, number, number])!;
    map.flyTo({
      ...camera,
      essential: true,
      padding: {
        left: 30,
        top: use_full_screen ? 30 : 5,
        right: 30,
        bottom: use_full_screen ? 30 : window.innerHeight / 4,
      },
    });
  }
}

////////////////////////////////////////////////////////////////////////////////
// COMPONENTS
////////////////////////////////////////////////////////////////////////////////

type DevicePopup = {
  lng: number,
  lat: number,
  text: string,
};

// LoadedScreeen is a component that presents tracks after data loadad
// via API calls, this allows to omit any unecessary null checks.
const LoadedScreeen = observer(() => {
  const ctx = useContext();
  const lang = useLang();
  const [map, setMap] = React.useState<mapboxgl.Map | null>(null);
  // Popup that shows on the map while hovering over device last location.
  const [popup, setDevicePopup] = React.useState<DevicePopup | null>(null);
  // Selected device id
  const [device_id, setDeviceId] = React.useState<string | null>(null);
  // Selectd device
  const [device, setDevice] = React.useState<AttachedDevice | null>(null);
  // Date for which statistics will be selected
  const [date, setDate] = React.useState<Date>(moment().toDate());
  // Device statistics, contains information about device localtion, speed,
  // sensor values etc. for given time interval.
  const [statistics, setStatistics] = React.useState<DeviceStatisticsResponse | null>(null);

  // Do we have any data?
  const has_data: boolean = (!!statistics && statistics.getLength() > 0);

  const points = userDevicesToPoints(ctx.devices);

  if (ctx.devices.length === 1) {
    if (device_id === null) {
      setDeviceId(ctx.devices[0].getId());
    }
  }

  React.useEffect(() => {
    if (map !== null) updateFieldsLayers(map, ctx.fields);
  }, [map, ctx.fields]);

  // Effect that responsible for loading device statics and displaing it on the map
  React.useEffect(() => {
    if (device_id === null) {
      // Nothing to do, may be I can hide something but for now it is not needed.
      return;
    }

    if (map === null) {
      return;
    }

    const from = moment(date).startOf('day').toDate();
    const to = moment(date).endOf('day').toDate();

    ctx.setLoading(true);
    console.time("track-load");

    const d = ctx.devices.find((d) => d.getId() === device_id);
    if (!d) {
      console.error("Failed to find device with id", device_id);
      return;
    }

    setDevice(d!);

    const width = d?.getSettings()?.getWidth() || 6;
    const delay = (d?.getSettings()?.getDelay() || 16000) / 1000;

    deviceStatistics(device_id, from, to).then((response) => {
      updateMap(map!, response, true, width, delay);
      setStatistics(response);
    }).catch((e) => {
      console.error("ERROR [API.DeviceStatistics]:", e);
    }).finally(() => {
      console.timeEnd("track-load");
      ctx.setLoading(false);
    });
  }, [map, device_id, date]);

  return (
    <>
      {ctx.loading ? <StyleOverlay><Spinner /></StyleOverlay> : null}
      <StyleSection>
        <LeftBar style={{ marginRight: 0 }} />
        <div style={{ width: "100%" }}>
          <StyleSection>
          {ctx.devices.length > 1
            ? <DevicesMenu
              hidden={false}
              onDeviceSelect={(device) => {
                if (device.getId() !== device_id) {
                  click("tracks.device-select");
                  setDeviceId(device.getId());
                }
              }}
            /> : null}
            <StyleMap>
              {has_data && <DeviceWindow
                statistics={statistics!}
                onZoom={(from, to) => {
                  if (!statistics || !device) {
                    console.warn("Zoom update without statistics data");
                    return;
                  }


                  const width = device?.getSettings()?.getWidth() || 6;
                  const delay = (device?.getSettings()?.getDelay() || 16000) / 1000;

                  updateMap(
                    map!,
                    statistics,
                    false,
                    width,
                    delay,
                    from,
                    to
                  ).catch(() => {
                    toast.error(lang.tracks.displayTrackError);
                  });
                }}
              />}
              <Map
                onLoad={(e) => {
                  const target = e.target;
                  setMap(target);

                  if (ctx.bbox !== null) {
                    target.fitBounds(ctx.bbox, {
                      linear: false,
                      maxDuration: 1,
                      padding: 30,
                    });
                  }

                  target.on("mousemove", "devices", (e) => {
                    const feature = (!!e.features) ? e.features[0] : null;
                    if (feature && feature.properties) {
                      const point = feature.geometry as Point;
                      setDevicePopup({
                        lng: point.coordinates[0],
                        lat: point.coordinates[1],
                        text: feature.properties["name"],
                      });
                    } else {
                      setDevicePopup(null);
                    }
                  });

                  target.on("mouseleave", "devices", () => setDevicePopup(null));
                }}
              >

                {(device_id && has_data) && <Dropdown
                    options={[
                      {
                        title: lang.tracks.sensorLayerTitle,
                        value: SENSOR_LAYER,
                      },
                      {
                        title: lang.tracks.speedLayerTitle,
                        value: SPEED_LAYER,
                      }
                    ]}
                    onSelect={(value: string) => {
                      if (map !== null) {
                        [SENSOR_LAYER, SPEED_LAYER].forEach((layer) => {
                          map.setLayoutProperty(layer, "visibility", 
                                                layer === value ? "visible" : "none");
                        });
                      }
                    }}
                  />
                }

                {device_id && <div style={{
                  position: "absolute",
                  zIndex: 1,
                  inset: "100px 24px auto auto",
                }}>
                  <DatePicker
                    selected={date}
                    onChange={(date) => {
                      if (date) {
                        click("tracks.date-select");
                        setDate(date);
                      } else {
                        console.warn("No date selected");
                      }
                    }} />
                </div>}

                <Source id={FIELDS_LAYER} type="geojson" data={emptyCollection}> 
                  <Layer {...{
                    source: FIELDS_LAYER,
                    type: 'fill',
                    id: FIELDS_LAYER,
                    paint: {
                      "fill-color": ["get", "fill"],
                      "fill-opacity": 0.5,
                      "fill-outline-color": ["get", "stroke"],
                    },
                  }}
                  />
                  <Layer {...{
                    source: FIELDS_LAYER,
                    type: "symbol",
                    layout: {
                      "text-field": ["get", "name"],
                      "text-variable-anchor": ["top", "bottom", "left", "right"],
                      "text-radial-offset": 0.5,
                      "text-justify": "auto",
                      "text-size": 20,
                    },
                    paint: {
                      "text-color": "#fff",
                      "text-halo-color": "#000",
                      "text-halo-width": 1,
                    }
                  }}
                  />
                </Source>
                <Source type="geojson" data={points}>
                  <Layer {...{
                    id: "devices",
                    type: "circle",
                    layout: {},
                    paint: {
                      "circle-radius": 5,
                      "circle-color": ["get", "fill"],
                      "circle-stroke-width": 1,
                      "circle-stroke-color": "rgb(255, 255, 255)",
                    },
                  }}
                  />
                </Source>
                <Source id={SPEED_LAYER} type="geojson" data={emptyCollection}> 
                  <Layer {...{
                    source: SPEED_LAYER,
                    type: 'line',
                    id: SPEED_LAYER,
                    layout: {
                      'line-cap': 'round',
                      'line-join': 'round',
                      'visibility': 'none',
                    },
                    paint: {
                      'line-color': ["get", "line-color"],
                      'line-width': [
                        "interpolate", ["linear"], ["zoom"],
                        5, 1,
                        14, 5,
                        20, 10
                      ],
                    }
                  }}
                  />
                </Source>
                <Source id={SENSOR_LAYER} type="geojson" data={emptyCollection}> 
                  <Layer {...{
                    source: SENSOR_LAYER,
                    type: 'fill',
                    id: SENSOR_LAYER,
                    layout: { 'visibility': 'visible' },
                    paint: {
                      "fill-outline-color": "rgba(0, 0, 0, 0)",
                      'fill-color': [
                        "interpolate", ["linear"], ["get", "sensor"],
                        0.0, "rgb(165.0, 0.0, 38.0)",
                        5.0, "rgb(215.0, 48.0, 39.0)",
                        10.0, "rgb(244.0, 109.0, 67.0)",
                        15.0, "rgb(253.0, 174.0, 97.0)",
                        20.0, "rgb(254.0, 224.0, 139.0)",
                        35.0, "rgb(255.0, 255.0, 191.0)",
                        45.0, "rgb(217.0, 239.0, 139.0)",
                        55.0, "rgb(166.0, 217.0, 106.0)",
                        62.5, "rgb(102.0, 189.0, 99.0)",
                        80.0, "rgb(26.0, 152.0, 80.0)",
                        90.0, "rgb(0.0, 104.0, 55.0)",
                        99.0, "rgb(37.0, 37.0, 37.0)",
                      ]
                    }
                  }}
                  />
                </Source>
                {popup && (
                  <Popup
                    longitude={popup.lng}
                    latitude={popup.lat}
                    closeButton={false}
                    offset={5}
                  >
                    <span>{popup.text}</span>
                  </Popup>
                )}
              </Map>
            </StyleMap>
          </StyleSection>
        </div>
      </StyleSection>
    </>
  );
});

const Screen: React.FC = () => {
  pageView("Traks");
  return <ContextProvider><LoadedScreeen /></ContextProvider>;
};

export default observer(Screen);
