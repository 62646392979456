import React, { useMemo } from "react";
import useVM from "@src/hooks/useVM";
import { makeAutoObservable, reaction } from "mobx";
import { RootStore, useStores } from "@stores";
import * as fieldsService from "@services/fieldsService";
import {
  TCrop,
  TFieldFeatures,
  TFieldStats,
  TLegendItem,
  TStatsItem,
} from "@services/fieldsService";
import Vector from "ol/source/Vector";
import VectorSource from "ol/source/Vector";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { TileJSON } from "ol/source";
import VectorLayer from "ol/layer/Vector";
import OpenLayersMap from "ol/Map";
import View from "ol/View";
import { Draw, Modify } from "ol/interaction";
import { doubleClick } from "ol/events/condition";
import { Geometry } from "ol/geom";
import { Fill, Stroke, Style } from "ol/style";
import { Coordinate } from "ol/coordinate";
import { Feature } from "ol";
import updateFeatureStyle from "@screens/FieldsScreen/fieldsScreenUtils";
import GeoJSON from "ol/format/GeoJSON";
import { getCenter } from "ol/extent";
import Polygon from "ol/geom/Polygon";
import { toast } from "react-toastify";
import { getArea } from "ol/sphere";
import { NavigateFunction, useNavigate } from "react-router-dom";

const ctx = React.createContext<FieldsScreenVM | null>(null);

export const FieldsScreenVMProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const rootStore = useStores();
  const store = useMemo(() => {
    return new FieldsScreenVM(rootStore, navigate);
  }, [rootStore, navigate]);
  return <ctx.Provider value={store}>{children}</ctx.Provider>;
};

export const useFieldsScreenVM = () => useVM(ctx);

const urlTempl = process.env.REACT_APP_HIGH_RES_SATELITE_API_URL_TEMPLATE!;

const style = [
  new Style({
    stroke: new Stroke({
      color: "#F6FF8F",
      width: 3,
    }),
    fill: new Fill({
      color: "rgba(246,255,143,0.5)",
    }),
  }),
];

export const optOptions = {
  dataProjection: "EPSG:4326",
  featureProjection: "EPSG:3857",
};

type TLegend = Record<string, Array<TLegendItem>>;
type TStatistics = Record<string, TStatsItem>;

class FieldsScreenVM {
  drawSource = new VectorSource({ wrapX: false });
  drawInteraction = new Draw({ source: this.drawSource, type: "Polygon" });
  modifyInteraction = new Modify({
    source: this.drawSource,
    deleteCondition: doubleClick,
  });
  drawLayer = new VectorLayer({ source: this.drawSource, style });

  heatmapSource = new Vector({ format: new GeoJSON() });
  fieldsSource = new Vector({ format: new GeoJSON() });

  fieldsLayer = new VectorLayer({ source: this.fieldsSource, style });
  heatmapLayer = new VectorLayer({
    source: this.heatmapSource,
    style,
  });
  satelliteLayer = new TileLayer({
    source: new XYZ({
      url: urlTempl,
      maxZoom: 22,
    }),
    minZoom: 12,
  });
  maptileLayer = new TileLayer({
    source: new TileJSON({
      url: "https://api.maptiler.com/maps/hybrid/tiles.json?key=L62xAk6HZWRCIJXQQghr",
      tileSize: 512,
      crossOrigin: "anonymous",
    }),
    maxZoom: 13,
  });

  map = new OpenLayersMap({
    layers: [
      this.satelliteLayer,
      this.maptileLayer,
      this.fieldsLayer,
      // this.heatmapLayer,
    ],
    controls: [],
    view: new View({ maxZoom: 22 }),
  });

  //======================================================================================
  private setupVector = (
    vector: Vector<Geometry>,
    response: TFieldFeatures
  ) => {
    const format = vector.getFormat() ?? new GeoJSON();
    const features = format.readFeatures(
      response.features,
      optOptions
    ) as Array<Feature<Geometry>>;
    features.forEach((feature) => {
      feature.setId(feature.get("id"));
      updateFeatureStyle(feature);
    });
    vector.clear(true);
    vector.addFeatures(features);
  };

  private setDefaultCenter = () => {
    const defaultMapCenter = [1644365.6088704763, 6262014.14719232];
    const defaultMapZoom = 5;
    const coordinates = this.fields.reduce((acc, feature) => {
      const extent = feature.getGeometry()?.getExtent();
      extent != null && acc.push(getCenter(extent));
      return acc;
    }, [] as Array<Coordinate>);
    const polygon = new Polygon([coordinates]);
    const view = this.map.getView();
    if (this.fields.length > 0 && coordinates.length > 0) {
      const mapCenter = getCenter(polygon.getExtent());
      view.setCenter(mapCenter);
      view.fit(polygon, { padding: [50, 50, 50, 50], maxZoom: 1000 });
    } else {
      view.setCenter(defaultMapCenter);
      view.setZoom(defaultMapZoom);
    }
  };
  //======================================================================================

  search: string = "";
  setSearch = (v: string) => (this.search = v);

  heatmapLegend: TLegend = {};
  private setHeatmapLegend = (legend: TLegend) => (this.heatmapLegend = legend);

  heatmapStatistics: TStatistics = {};
  private setHeatmapStatistics = (stats: TStatistics) =>
    (this.heatmapStatistics = stats);

  fields: Feature<Geometry>[] = [];
  private setFields = (f: Feature<Geometry>[]) => (this.fields = f);

  isPolygonReady: boolean = false;
  setIsPolygonReady = (v: boolean) => (this.isPolygonReady = v);

  isFieldEdit: boolean = false;
  setIsFieldEdit = (v: boolean) => (this.isFieldEdit = v);

  loading: boolean = true;
  private setLoading = (v: boolean) => (this.loading = v);

  private selectedFieldReaction = async () => {
    this.setLoading(true);
    const id = String(this.selectedFieldId);
    this.resetLayers();

    if (this.selectedFieldView === "yield" && id) {
      const feature = this.heatmapSource.getFeatureById(id);
      if (feature == null && feature) {
        try {
          const response = await fieldsService.getHeatmapData(String(id));
          this.setupVector(this.heatmapSource, response);
        } catch (e) {
          console.warn(e);
        }
      }
    }
    this.setLoading(false);
  };

  private resetLayers = () => {
    const layers = [
      this.satelliteLayer,
      this.maptileLayer,
      this.isFieldEdit ? this.drawLayer : this.fieldsLayer,
    ];
    if (this.selectedFieldView === "yield") layers.push(this.heatmapLayer);
    this.map.setLayers(layers);
  };

  selectedFieldView = "border";
  setSelectedFieldView = (v: "border" | "yield") => {
    // const q = new URLSearchParams(window.location.search);
    // q.set("v", v);
    // this.navigate(window.location.pathname + "?" + q.toString(), { replace: true });

    this.selectedFieldView = v;
  }

  selectedFieldId: string | number | null = null;
  private setSelectedFieldId = (v: string | number | null) =>
    (this.selectedFieldId = v);

  get selectedField() {
    return this.selectedFieldId != null
      ? this.fieldsSource.getFeatureById(this.selectedFieldId)
      : null;
  }
  crops: TCrop[] = [];
  private setCrops = (crops: TCrop[]) => (this.crops = crops);
  getCropById = (id: string) => this.crops.find((crop) => crop.id === id);

  constructor(
    private rootStore: RootStore,
    private navigate: NavigateFunction,
  ) {
    makeAutoObservable(this);

    fieldsService.getCropsData().then(this.setCrops);
    this.loadFields().then(() => {
      this.setDefaultCenter();

      reaction(
        () => [this.rootStore.seasonStore.currentSeasonId],
        () => {
          this.loadFields().then(() => {
            this.setDefaultCenter();
            this.setSelectedFieldId(null);
            this.setSelectedFieldView("border");
          })
        }
      );

      reaction(
        () => [this.selectedFieldId, this.selectedFieldView],
        this.selectedFieldReaction
      );
    });
  }

  loadFields = async () => {
    this.setLoading(true);
    return fieldsService
      .getFieldsData(this.rootStore.seasonStore.currentSeasonId)
      .then((fields) => {
        this.setupVector(this.fieldsSource, fields);
        this.setFields(this.fieldsSource.getFeatures());
        this.setLoading(false);
      });
  }


  save = async ({ cropId, name }: { cropId: string; name: string }) => {
    if (!this.selectedFieldId || !this.isFieldEdit || !this.isPolygonReady) {
      return;
    }
    const feature = this.drawSource.getFeatureById(this.selectedFieldId);
    const props = feature?.getProperties();
    if (feature == null || props == null) return;
    feature.setProperties({ ...props, name, culture_id: cropId });

    const field = this.fieldsSource.getFeatureById(this.selectedFieldId);
    field?.setProperties(feature.getProperties());
    field?.setGeometry(feature.getGeometry());

    this.handleCancel();
    const format = this.fieldsSource.getFormat() as GeoJSON;
    const featureObject = format.writeFeatureObject(field!, optOptions);
    await fieldsService
      .updateField(featureObject)
      .then(this.loadFields)
      .catch((e) => toast.error(e.message ?? e.toString()));
  };

  importFromFile = (f: File, season_id?: string) =>
    fieldsService.importFromFile(f, season_id).then(() => this.loadFields());

  create = async ({ cropId, name }: { cropId: string; name: string }) => {
    if (!this.isPolygonReady) return;
    const feature = this.drawSource.getFeatures()[0];
    const props = feature?.getProperties();
    if (feature == null || props == null) return;
    feature.setProperties({ ...props, name, culture_id: cropId });

    this.handleCancel();
    const format = this.fieldsSource.getFormat() as GeoJSON;
    const featureObject = format.writeFeatureObject(feature, optOptions);
    await fieldsService
      .createField(featureObject, this.rootStore.seasonStore.currentSeasonId)
      .then(this.loadFields)
      .catch((e) => {
        console.error(e);
        toast.error(e.message ?? e.toString())
      });
  };

  selectField = (fieldId: string | number) => {
    const feature = this.fieldsSource.getFeatureById(fieldId);
    if (feature === null) return;
    const extent = feature.getGeometry()?.getExtent();
    const padding = [20, 20, 20, 20];
    extent && this.map.getView().fit(extent, { padding });

    this.setSelectedFieldId(fieldId);
    this.fields.forEach((f) => {
      updateFeatureStyle(f, { selected: f.getId() === this.selectedFieldId });
    });
    this.heatmapSource.getFeatureById(fieldId) == null &&
      fieldsService.getHeatmapData(String(fieldId)).then((response) => {
        // this.setupVector(this.heatmapSource, response);
        //-------------------
        const format = this.heatmapSource.getFormat() ?? new GeoJSON();
        const features = format.readFeatures(
          response.features,
          optOptions
        ) as Array<Feature<Geometry>>;
        features.forEach((feature) => {
          feature.setId(feature.get("id"));
          updateFeatureStyle(feature);
        });
        this.heatmapSource.clear(true);
        this.heatmapSource.addFeatures(features);
        //-------------------
        const legend = { ...this.heatmapLegend };
        legend[String(fieldId)] = response.legend;
        this.setHeatmapLegend(legend);
        const statistics = { ...this.heatmapStatistics };
        statistics[String(fieldId)] = response.stats;
        this.setHeatmapStatistics(statistics);
      });

    const q = new URLSearchParams(window.location.search);
    q.set("fid", fieldId.toString());
    this.navigate(window.location.pathname + "?" + q.toString(), { replace: true });
  };

  handleFieldUpdate = (id: string | number) => {
    this.selectField(id);

    const feature = this.fieldsSource.getFeatureById(id);
    if (feature === null) {
      console.error("Failed to find feature for field: ", id);
      return;
    }

    this.drawSource.clear(true);
    this.drawSource.addFeature(feature.clone());
    this.map.addInteraction(this.modifyInteraction);
    this.drawSource.getFeatures().forEach((feature) => {
      feature.setId(feature.get("id"));
    });

    this.setIsFieldEdit(true);
    this.setIsPolygonReady(true);
    this.resetLayers();
  };

  handleFieldCreate = () => {
    this.drawSource.clear(true);
    this.map.addInteraction(this.drawInteraction);

    this.drawInteraction.once("drawend", (event) => {
      this.map.removeInteraction(this.drawInteraction);
      this.map.addInteraction(this.modifyInteraction);
      this.setIsPolygonReady(true);
    });

    this.setIsFieldEdit(true);
    this.setIsPolygonReady(false);
    this.setSelectedFieldId(null);
    this.resetLayers();
  };

  handleCancel = () => {
    this.drawInteraction.abortDrawing();

    this.map.removeInteraction(this.drawInteraction);
    this.map.removeInteraction(this.modifyInteraction);

    this.drawSource.clear(true);

    this.setIsFieldEdit(false);
    this.setIsPolygonReady(false);
    this.resetLayers();
    this.fields.forEach((f) => {
      updateFeatureStyle(f, { selected: f.getId() === this.selectedFieldId });
    });
  };

  deleteField = (id: string | number) =>
    fieldsService.deleteField(id).then(this.loadFields).then(this.handleCancel);
}

export const isRecentlyUpdated = (stats: TFieldStats | null): boolean => {
  return !!stats;
};

export const getFieldArea = (field?: Feature<Geometry>): string | undefined => {
  if (field == null) return;
  const geometry = field.getGeometry();
  const area = geometry != null ? getArea(geometry) : undefined;
  return area != null ? `${(area / 10000).toFixed(2)} Ha` : undefined;
};
