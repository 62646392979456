/**
 * @fileoverview gRPC-Web generated client stub for grass.fields.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.26.1
// source: apis/fields/v1/api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as apis_fields_v1_api_pb from '../../../apis/fields/v1/api_pb'; // proto import: "apis/fields/v1/api.proto"


export class FieldsAPIClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetFields = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.FieldsAPI/GetFields',
    grpcWeb.MethodType.SERVER_STREAMING,
    apis_fields_v1_api_pb.GetFieldsRequest,
    apis_fields_v1_api_pb.GetFieldsResponse,
    (request: apis_fields_v1_api_pb.GetFieldsRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.GetFieldsResponse.deserializeBinary
  );

  getFields(
    request: apis_fields_v1_api_pb.GetFieldsRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.GetFieldsResponse> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/grass.fields.v1.FieldsAPI/GetFields',
      request,
      metadata || {},
      this.methodDescriptorGetFields);
  }

  methodDescriptorFieldStatistics = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.FieldsAPI/FieldStatistics',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.FieldStatisticsRequest,
    apis_fields_v1_api_pb.FieldStatisticsResponse,
    (request: apis_fields_v1_api_pb.FieldStatisticsRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.FieldStatisticsResponse.deserializeBinary
  );

  fieldStatistics(
    request: apis_fields_v1_api_pb.FieldStatisticsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.FieldStatisticsResponse>;

  fieldStatistics(
    request: apis_fields_v1_api_pb.FieldStatisticsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.FieldStatisticsResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.FieldStatisticsResponse>;

  fieldStatistics(
    request: apis_fields_v1_api_pb.FieldStatisticsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.FieldStatisticsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.FieldsAPI/FieldStatistics',
        request,
        metadata || {},
        this.methodDescriptorFieldStatistics,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.FieldsAPI/FieldStatistics',
    request,
    metadata || {},
    this.methodDescriptorFieldStatistics);
  }

  methodDescriptorCheckField = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.FieldsAPI/CheckField',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.CheckFieldRequest,
    apis_fields_v1_api_pb.CheckFieldResponse,
    (request: apis_fields_v1_api_pb.CheckFieldRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.CheckFieldResponse.deserializeBinary
  );

  checkField(
    request: apis_fields_v1_api_pb.CheckFieldRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.CheckFieldResponse>;

  checkField(
    request: apis_fields_v1_api_pb.CheckFieldRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.CheckFieldResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.CheckFieldResponse>;

  checkField(
    request: apis_fields_v1_api_pb.CheckFieldRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.CheckFieldResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.FieldsAPI/CheckField',
        request,
        metadata || {},
        this.methodDescriptorCheckField,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.FieldsAPI/CheckField',
    request,
    metadata || {},
    this.methodDescriptorCheckField);
  }

  methodDescriptorListFields = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.FieldsAPI/ListFields',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.ListFieldsRequest,
    apis_fields_v1_api_pb.ListFieldsResponse,
    (request: apis_fields_v1_api_pb.ListFieldsRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.ListFieldsResponse.deserializeBinary
  );

  listFields(
    request: apis_fields_v1_api_pb.ListFieldsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.ListFieldsResponse>;

  listFields(
    request: apis_fields_v1_api_pb.ListFieldsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.ListFieldsResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.ListFieldsResponse>;

  listFields(
    request: apis_fields_v1_api_pb.ListFieldsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.ListFieldsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.FieldsAPI/ListFields',
        request,
        metadata || {},
        this.methodDescriptorListFields,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.FieldsAPI/ListFields',
    request,
    metadata || {},
    this.methodDescriptorListFields);
  }

  methodDescriptorGetField = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.FieldsAPI/GetField',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.GetFieldRequest,
    apis_fields_v1_api_pb.GetFieldResponse,
    (request: apis_fields_v1_api_pb.GetFieldRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.GetFieldResponse.deserializeBinary
  );

  getField(
    request: apis_fields_v1_api_pb.GetFieldRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.GetFieldResponse>;

  getField(
    request: apis_fields_v1_api_pb.GetFieldRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.GetFieldResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.GetFieldResponse>;

  getField(
    request: apis_fields_v1_api_pb.GetFieldRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.GetFieldResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.FieldsAPI/GetField',
        request,
        metadata || {},
        this.methodDescriptorGetField,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.FieldsAPI/GetField',
    request,
    metadata || {},
    this.methodDescriptorGetField);
  }

  methodDescriptorCurrentSeason = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.FieldsAPI/CurrentSeason',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.CurrentSeasonRequest,
    apis_fields_v1_api_pb.CurrentSeasonResponse,
    (request: apis_fields_v1_api_pb.CurrentSeasonRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.CurrentSeasonResponse.deserializeBinary
  );

  currentSeason(
    request: apis_fields_v1_api_pb.CurrentSeasonRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.CurrentSeasonResponse>;

  currentSeason(
    request: apis_fields_v1_api_pb.CurrentSeasonRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.CurrentSeasonResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.CurrentSeasonResponse>;

  currentSeason(
    request: apis_fields_v1_api_pb.CurrentSeasonRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.CurrentSeasonResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.FieldsAPI/CurrentSeason',
        request,
        metadata || {},
        this.methodDescriptorCurrentSeason,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.FieldsAPI/CurrentSeason',
    request,
    metadata || {},
    this.methodDescriptorCurrentSeason);
  }

  methodDescriptorCreateSeason = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.FieldsAPI/CreateSeason',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.CreateSeasonRequest,
    apis_fields_v1_api_pb.CreateSeasonResponse,
    (request: apis_fields_v1_api_pb.CreateSeasonRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.CreateSeasonResponse.deserializeBinary
  );

  createSeason(
    request: apis_fields_v1_api_pb.CreateSeasonRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.CreateSeasonResponse>;

  createSeason(
    request: apis_fields_v1_api_pb.CreateSeasonRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.CreateSeasonResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.CreateSeasonResponse>;

  createSeason(
    request: apis_fields_v1_api_pb.CreateSeasonRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.CreateSeasonResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.FieldsAPI/CreateSeason',
        request,
        metadata || {},
        this.methodDescriptorCreateSeason,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.FieldsAPI/CreateSeason',
    request,
    metadata || {},
    this.methodDescriptorCreateSeason);
  }

  methodDescriptorUpdateSeason = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.FieldsAPI/UpdateSeason',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.UpdateSeasonRequest,
    apis_fields_v1_api_pb.UpdateSeasonResponse,
    (request: apis_fields_v1_api_pb.UpdateSeasonRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.UpdateSeasonResponse.deserializeBinary
  );

  updateSeason(
    request: apis_fields_v1_api_pb.UpdateSeasonRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.UpdateSeasonResponse>;

  updateSeason(
    request: apis_fields_v1_api_pb.UpdateSeasonRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.UpdateSeasonResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.UpdateSeasonResponse>;

  updateSeason(
    request: apis_fields_v1_api_pb.UpdateSeasonRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.UpdateSeasonResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.FieldsAPI/UpdateSeason',
        request,
        metadata || {},
        this.methodDescriptorUpdateSeason,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.FieldsAPI/UpdateSeason',
    request,
    metadata || {},
    this.methodDescriptorUpdateSeason);
  }

  methodDescriptorDeleteSeason = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.FieldsAPI/DeleteSeason',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.DeleteSeasonRequest,
    apis_fields_v1_api_pb.DeleteSeasonResponse,
    (request: apis_fields_v1_api_pb.DeleteSeasonRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.DeleteSeasonResponse.deserializeBinary
  );

  deleteSeason(
    request: apis_fields_v1_api_pb.DeleteSeasonRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.DeleteSeasonResponse>;

  deleteSeason(
    request: apis_fields_v1_api_pb.DeleteSeasonRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.DeleteSeasonResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.DeleteSeasonResponse>;

  deleteSeason(
    request: apis_fields_v1_api_pb.DeleteSeasonRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.DeleteSeasonResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.FieldsAPI/DeleteSeason',
        request,
        metadata || {},
        this.methodDescriptorDeleteSeason,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.FieldsAPI/DeleteSeason',
    request,
    metadata || {},
    this.methodDescriptorDeleteSeason);
  }

  methodDescriptorListSeason = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.FieldsAPI/ListSeason',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.ListSeasonRequest,
    apis_fields_v1_api_pb.ListSeasonResponse,
    (request: apis_fields_v1_api_pb.ListSeasonRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.ListSeasonResponse.deserializeBinary
  );

  listSeason(
    request: apis_fields_v1_api_pb.ListSeasonRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.ListSeasonResponse>;

  listSeason(
    request: apis_fields_v1_api_pb.ListSeasonRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.ListSeasonResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.ListSeasonResponse>;

  listSeason(
    request: apis_fields_v1_api_pb.ListSeasonRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.ListSeasonResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.FieldsAPI/ListSeason',
        request,
        metadata || {},
        this.methodDescriptorListSeason,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.FieldsAPI/ListSeason',
    request,
    metadata || {},
    this.methodDescriptorListSeason);
  }

}

export class APIClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorField = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.API/Field',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.FieldRequest,
    apis_fields_v1_api_pb.FieldResponse,
    (request: apis_fields_v1_api_pb.FieldRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.FieldResponse.deserializeBinary
  );

  field(
    request: apis_fields_v1_api_pb.FieldRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.FieldResponse>;

  field(
    request: apis_fields_v1_api_pb.FieldRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.FieldResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.FieldResponse>;

  field(
    request: apis_fields_v1_api_pb.FieldRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.FieldResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.API/Field',
        request,
        metadata || {},
        this.methodDescriptorField,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.API/Field',
    request,
    metadata || {},
    this.methodDescriptorField);
  }

  methodDescriptorFields = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.API/Fields',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.FieldsRequest,
    apis_fields_v1_api_pb.FieldsResponse,
    (request: apis_fields_v1_api_pb.FieldsRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.FieldsResponse.deserializeBinary
  );

  fields(
    request: apis_fields_v1_api_pb.FieldsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.FieldsResponse>;

  fields(
    request: apis_fields_v1_api_pb.FieldsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.FieldsResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.FieldsResponse>;

  fields(
    request: apis_fields_v1_api_pb.FieldsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.FieldsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.API/Fields',
        request,
        metadata || {},
        this.methodDescriptorFields,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.API/Fields',
    request,
    metadata || {},
    this.methodDescriptorFields);
  }

  methodDescriptorFieldProductivity = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.API/FieldProductivity',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.FieldProductivityRequest,
    apis_fields_v1_api_pb.FieldProductivityResponse,
    (request: apis_fields_v1_api_pb.FieldProductivityRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.FieldProductivityResponse.deserializeBinary
  );

  fieldProductivity(
    request: apis_fields_v1_api_pb.FieldProductivityRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.FieldProductivityResponse>;

  fieldProductivity(
    request: apis_fields_v1_api_pb.FieldProductivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.FieldProductivityResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.FieldProductivityResponse>;

  fieldProductivity(
    request: apis_fields_v1_api_pb.FieldProductivityRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.FieldProductivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.API/FieldProductivity',
        request,
        metadata || {},
        this.methodDescriptorFieldProductivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.API/FieldProductivity',
    request,
    metadata || {},
    this.methodDescriptorFieldProductivity);
  }

  methodDescriptorFieldCreate = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.API/FieldCreate',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.FieldCreateRequest,
    apis_fields_v1_api_pb.FieldCreateResponse,
    (request: apis_fields_v1_api_pb.FieldCreateRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.FieldCreateResponse.deserializeBinary
  );

  fieldCreate(
    request: apis_fields_v1_api_pb.FieldCreateRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.FieldCreateResponse>;

  fieldCreate(
    request: apis_fields_v1_api_pb.FieldCreateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.FieldCreateResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.FieldCreateResponse>;

  fieldCreate(
    request: apis_fields_v1_api_pb.FieldCreateRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.FieldCreateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.API/FieldCreate',
        request,
        metadata || {},
        this.methodDescriptorFieldCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.API/FieldCreate',
    request,
    metadata || {},
    this.methodDescriptorFieldCreate);
  }

  methodDescriptorFieldUpdate = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.API/FieldUpdate',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.FieldUpdateRequest,
    apis_fields_v1_api_pb.FieldUpdateResponse,
    (request: apis_fields_v1_api_pb.FieldUpdateRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.FieldUpdateResponse.deserializeBinary
  );

  fieldUpdate(
    request: apis_fields_v1_api_pb.FieldUpdateRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.FieldUpdateResponse>;

  fieldUpdate(
    request: apis_fields_v1_api_pb.FieldUpdateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.FieldUpdateResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.FieldUpdateResponse>;

  fieldUpdate(
    request: apis_fields_v1_api_pb.FieldUpdateRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.FieldUpdateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.API/FieldUpdate',
        request,
        metadata || {},
        this.methodDescriptorFieldUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.API/FieldUpdate',
    request,
    metadata || {},
    this.methodDescriptorFieldUpdate);
  }

  methodDescriptorFieldDelete = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.API/FieldDelete',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.FieldDeleteRequest,
    apis_fields_v1_api_pb.FieldDeleteResponse,
    (request: apis_fields_v1_api_pb.FieldDeleteRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.FieldDeleteResponse.deserializeBinary
  );

  fieldDelete(
    request: apis_fields_v1_api_pb.FieldDeleteRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.FieldDeleteResponse>;

  fieldDelete(
    request: apis_fields_v1_api_pb.FieldDeleteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.FieldDeleteResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.FieldDeleteResponse>;

  fieldDelete(
    request: apis_fields_v1_api_pb.FieldDeleteRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.FieldDeleteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.API/FieldDelete',
        request,
        metadata || {},
        this.methodDescriptorFieldDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.API/FieldDelete',
    request,
    metadata || {},
    this.methodDescriptorFieldDelete);
  }

  methodDescriptorSeason = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.API/Season',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.SeasonRequest,
    apis_fields_v1_api_pb.SeasonResponse,
    (request: apis_fields_v1_api_pb.SeasonRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.SeasonResponse.deserializeBinary
  );

  season(
    request: apis_fields_v1_api_pb.SeasonRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.SeasonResponse>;

  season(
    request: apis_fields_v1_api_pb.SeasonRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.SeasonResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.SeasonResponse>;

  season(
    request: apis_fields_v1_api_pb.SeasonRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.SeasonResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.API/Season',
        request,
        metadata || {},
        this.methodDescriptorSeason,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.API/Season',
    request,
    metadata || {},
    this.methodDescriptorSeason);
  }

  methodDescriptorSeasons = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.API/Seasons',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.SeasonsRequest,
    apis_fields_v1_api_pb.SeasonsResponse,
    (request: apis_fields_v1_api_pb.SeasonsRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.SeasonsResponse.deserializeBinary
  );

  seasons(
    request: apis_fields_v1_api_pb.SeasonsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.SeasonsResponse>;

  seasons(
    request: apis_fields_v1_api_pb.SeasonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.SeasonsResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.SeasonsResponse>;

  seasons(
    request: apis_fields_v1_api_pb.SeasonsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.SeasonsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.API/Seasons',
        request,
        metadata || {},
        this.methodDescriptorSeasons,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.API/Seasons',
    request,
    metadata || {},
    this.methodDescriptorSeasons);
  }

  methodDescriptorSeasonCreate = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.API/SeasonCreate',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.SeasonCreateRequest,
    apis_fields_v1_api_pb.SeasonCreateResponse,
    (request: apis_fields_v1_api_pb.SeasonCreateRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.SeasonCreateResponse.deserializeBinary
  );

  seasonCreate(
    request: apis_fields_v1_api_pb.SeasonCreateRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.SeasonCreateResponse>;

  seasonCreate(
    request: apis_fields_v1_api_pb.SeasonCreateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.SeasonCreateResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.SeasonCreateResponse>;

  seasonCreate(
    request: apis_fields_v1_api_pb.SeasonCreateRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.SeasonCreateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.API/SeasonCreate',
        request,
        metadata || {},
        this.methodDescriptorSeasonCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.API/SeasonCreate',
    request,
    metadata || {},
    this.methodDescriptorSeasonCreate);
  }

  methodDescriptorSeasonDelete = new grpcWeb.MethodDescriptor(
    '/grass.fields.v1.API/SeasonDelete',
    grpcWeb.MethodType.UNARY,
    apis_fields_v1_api_pb.SeasonDeleteRequest,
    apis_fields_v1_api_pb.SeasonDeleteResponse,
    (request: apis_fields_v1_api_pb.SeasonDeleteRequest) => {
      return request.serializeBinary();
    },
    apis_fields_v1_api_pb.SeasonDeleteResponse.deserializeBinary
  );

  seasonDelete(
    request: apis_fields_v1_api_pb.SeasonDeleteRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_fields_v1_api_pb.SeasonDeleteResponse>;

  seasonDelete(
    request: apis_fields_v1_api_pb.SeasonDeleteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.SeasonDeleteResponse) => void): grpcWeb.ClientReadableStream<apis_fields_v1_api_pb.SeasonDeleteResponse>;

  seasonDelete(
    request: apis_fields_v1_api_pb.SeasonDeleteRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_fields_v1_api_pb.SeasonDeleteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.fields.v1.API/SeasonDelete',
        request,
        metadata || {},
        this.methodDescriptorSeasonDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.fields.v1.API/SeasonDelete',
    request,
    metadata || {},
    this.methodDescriptorSeasonDelete);
  }

}

