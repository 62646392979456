import React from "react";

import _ from "lodash";
import * as d3 from "d3-interpolate";
import mapbox from "mapbox-gl";
import { bbox as turfBBox, area as turfArea } from "@turf/turf";
import { toast } from "react-toastify";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import { Column, Row } from "@src/components/Flex";
import Dialog from "@components/Dialog";
import Spinner from "@components/Spinner";
import GeoMap, { Source, Layer, GeoJSONSource } from "@components/Map";
import Input from "@components/Input";
import Button from "@components/Button";
import { ReactComponent as ExpandLess } from "@assets/icons/expand_less.svg";
import { ReactComponent as ExpandMore } from "@assets/icons/expand_more.svg";
import { fetchPrescription } from "@services/mowerService";
import { useLang } from "@src/hooks/useLang";

type State<S> = [S, React.Dispatch<React.SetStateAction<S>>];

export const cn = (...classes: string[]): string => {
  return _.join(_.filter(_.uniq(classes), (e) => !_.isEmpty(e)), " ");
};

const LoadingLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  background: rgba(256, 256, 256, 0.5);
  z-index: 20;
`;

const StyleScreen = styled.div`
  height: 100vh;
`;

const StyleModalWindow = styled.div`
  padding: 0.75rem;
  background-color: #fff;
  border-radius: 0.25rem;
  width: 24rem;
  z-index: 10;
  top: 0.5rem;
  left: 0.5rem;
  position: absolute;
`;

const StyleModalHeader = styled.div`
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
`;

const StyleModalHeaderText = styled.div`
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
`;

const StyleModalContainer = styled.div<{ hidden: boolean }>`
  padding-top: 0.75rem;
  display: ${({ hidden }) => (hidden) ? "none" : "block"};
`;

const StyleCountSelect = styled.div`
  --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,.05);
  --tw-shadow-colored: inset 0 2px 4px 0;
  box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  color: rgb(107 114 128);
  font-weight: 500;
  font-size: .875rem;
  line-height: 1.25rem;
  text-align: center;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: rgb(229 231 235);
  border-radius: 0.25rem;
  gap: 0.25rem;
  display: flex;
`;

const StyleSelect = styled.div<{ selected: boolean }>`
  color: #000;
  border-radius: 0.25rem;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  -webkit-user-select: none;
  user-select: none;
  background-color: ${({ selected }) => (selected) ? "#fff" : "transparent"}
`;

const StyleRateInputContainer = styled.div`
  padding: 1rem 0;
`;

const StyleOptionsHeader = styled.div`
  -webkit-columns: 3;
  column-count: 3;
`;

const StyleOptionsHeaderCell = styled.div`
  font-weight: 600;
  padding-left: 0.5rem;
  padding-bottom: 0.25rem;
`;

const StyleOptionsContainer = styled.div`
  margin-right: 0.25rem;
  max-height: 50vh;
  overflow-y: auto;
`;

const StyleOptionsRow = styled.div`
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #e5e7eb;

  :first {
    border-top-width: 0;
  }
`;

const StyleOptionsRowContainer = styled.div<{ borderColor: string }>`
  display: grid;
  grid-template-columns: repeat(3,minmax(0,1fr));
  border-right: 4px solid ${({ borderColor }) => borderColor};
`;

const StyleSubtext = styled.span`
  color: rgb(156 163 175);
  font-size: .875rem;
  line-height: 1.25rem;
`;

const StyleOptionsInputWrapper = styled.div`
  padding-top: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  grid-column: span 2/span 2;
`;

const StyleActions = styled.div`
  position: absolute;
  display: flex;
  gap: 0.5rem;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 10;
  padding: 0.25rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  -webkit-user-select: none;
  user-select: none;
`;

// Zone contains information about zone of the prescription map.
type Zone = {
  // Zone id
  id: number;
  // Zone rate
  rate: number;
  // Text representation of the rate
  raw_rate: string;
  // Area in square meters
  area: number;
  // Color of the zone on the map
  color: string;
};

// TODO(nk2ge5k): I feel like this is not correct - zones with greater
// yield should be fertilized less, I think it make more sense.
const getRate = (zone: Zone, count: number, standard: number): [number, string] => {
  if (zone.raw_rate === "") {
    const mid = _.round(count / 2, 0);
    const diff = Math.abs(mid - zone.id);

    let rate: number;
    if (zone.id < mid) {
      rate = _.round(standard + ((standard / 100) * (5 * diff)), 2);
    } else if (zone.id > mid) {
      rate = _.round(standard - ((standard / 100) * (5 * diff)), 2);
    } else {
      rate = _.round(standard, 2);
    }

    return [rate, rate.toString()];
  }

  return [zone.rate, zone.raw_rate];
}


const pallet = d3.interpolateRgbBasisClosed([
  "rgba(9, 52, 12, 1)",
  "rgba(17, 94, 13, 1)",
  "rgba(54, 155, 9, 1)",
  "rgba(147, 212, 11, 1)",
  "rgba(254, 246, 32, 1)",
  "rgba(237, 142, 7, 1)",
  "rgba(170, 57, 15, 1)",
  "rgba(82, 18, 0, 1)",
]);


const interpolate = (n: number, max: number) => {
  return pallet(1 / (max + 1) * n);
};

// useFieldID is an effect that returns field id from the page url.
const useFieldID = (): [string, string | null] => {
  const params = useParams();
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const field_id = params.field_id;
  if (!field_id) {
    throw new Error("Invalid page address");
  }
  return [field_id, location.state.field_name ?? search.get("field_name")];
};

const CountSelect: React.FC<{ countState: State<number> }> = ({ countState }) => {
  const lang = useLang();

  const options = [3, 5, 7, 9];
  const [count, setCount] = countState;
  return (
    <div>
      <div style={{ paddingLeft: "0.5rem" }}>
        <StyleSubtext>{lang.prescription.zones}</StyleSubtext>
      </div>
      <StyleCountSelect>
        {
          options.map((option) => {
            const selected = (count === option);
            return (
              <div
                key={option}
                style={{ flexGrow: 1, cursor: "pointer" }}
                onClick={() => setCount(option)}
              >
                <StyleSelect selected={selected}>{option}</StyleSelect>
              </div>
            );
          })
        }
      </StyleCountSelect>
    </div>
  );
};

const formatDownloadURL = (field_id: string, field_name: string | null, rates: number[], format: string): string => {
  const baseURL = `https://at.greengrowth.tech/api/mower/v1/${field_id}/prescription`;
  const q = new URLSearchParams();

  q.append("format", format);
  rates.reverse().forEach((rate) => { q.append("rates", rate.toString()); });

  if (field_name) {
    q.append("field_name", field_name.replaceAll(" ", "_"));
  }

  return baseURL + "?" + q.toString();
};

const DownloadDialog: React.FC<{
  field_id: string,
  field_name: string | null,
  standard: number,
  zones: Zone[],
  visible: boolean,
  onClose: () => void;
}> = ({ field_id, field_name, standard, zones, visible, onClose }) => {
  const lang = useLang();

  const rates = zones.map((zone) => {
    let [rate,] = getRate(zone, zones.length, standard);
    return rate;
  });

  const clickDownload = (format: string) => {
    const link = document.createElement("a");
    link.href = formatDownloadURL(field_id, field_name, rates, format);
    link.click();

    onClose();
  };


  return (
    <Dialog
      title={lang.prescription.download}
      visible={visible}
      onClose={onClose}
      style={{ maxWidth: 500 }}
    >
      <Column
        justifyContent="space-between"
        crossAxisSize="max"
        mainAxisSize="stretch">
        <Row alignItems="center" style={{ marginTop: 16, gap: 32 }}>
          <Button
            style={{ minHeight: 42 }}
            onClick={() => clickDownload("shp")}>
            Shapfile
          </Button>
          <Button
            style={{ minHeight: 42 }}
            onClick={() => clickDownload("deere")}>
            John Deere
          </Button>
        </Row>
        <Row alignItems="center" style={{ marginTop: 16, gap: 32 }}>
          <Button
            style={{ minHeight: 42 }}
            onClick={() => clickDownload("KML")}>
            KML
          </Button>
          <Button
            style={{ minHeight: 42 }}
            onClick={() => clickDownload("geojson")}>
            GeoJSON
          </Button>
        </Row>
      </Column>
    </Dialog>
  );
}

const StandardRateInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => {
  const lang = useLang();

  return (
    <StyleRateInputContainer>
      <div style={{ paddingLeft: "0.5rem" }}>
        <StyleSubtext>{lang.prescription.standardRate} </StyleSubtext>
      </div>
      <Input
        type="text"
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
      />
    </StyleRateInputContainer>
  );
};

const Options: React.FC<{
  zones: Zone[];
  standard: number;
  setRate: (id: number, rate: string) => void;
}> = ({ zones, standard, setRate }) => {
  const lang = useLang();

  return (
    <StyleOptionsContainer>
      <StyleOptionsHeader>
        <StyleOptionsHeaderCell>{lang.prescription.zone}</StyleOptionsHeaderCell>
        <StyleOptionsHeaderCell>{lang.prescription.rate}</StyleOptionsHeaderCell>
      </StyleOptionsHeader>
      {
        zones.map((zone) => {
          const [, raw_rate] = getRate(zone, zones.length, standard);

          return <StyleOptionsRow key={zone.id}>
            <StyleOptionsRowContainer borderColor={zone.color}>
              <div>
                <div>{lang.prescription.zone} {zone.id}</div>
                <StyleSubtext>
                  {_.round(zone.area, 2)} m²
                </StyleSubtext>
              </div>
              <StyleOptionsInputWrapper>
                <Input
                  type="text"
                  value={raw_rate}
                  onChange={(e) => {
                    setRate(zone.id, e.target.value);
                  }}
                />
              </StyleOptionsInputWrapper>
            </StyleOptionsRowContainer>
          </StyleOptionsRow>
        })
      }
    </StyleOptionsContainer>
  );
};


const Prescription: React.FC = () => {
  const [field_id, field_name] = useFieldID();
  const navigate = useNavigate();
  const lang = useLang();

  const [loading, setLoading] = React.useState(true);
  // Number of the zones
  const [count, setCount] = React.useState<number>(3);
  // Standard rate
  const [standard, setStandard] = React.useState<number>(100);
  // Raw text value of standard rate
  const [rawStandard, setRawStandard] = React.useState<string>(standard.toString());
  // Visiblity of DownloadDialog
  const [isExportDialogVisible, setExportDialogVisible] = React.useState<boolean>(false);

  const [hidden, setHidden] = React.useState<boolean>(false);
  const [map, setMap] = React.useState<mapbox.Map | null>(null);
  const [zones, setZones] = React.useState<Zone[]>([]);

  // effect that loads prescription from MowerAPI every time when count changes.
  React.useEffect(() => {
    if (map === null) {
      console.warn("Map not loaded");
      return;
    }

    const source = map.getSource("prescription");
    if (!source) {
      console.warn("Cannot find prescription source");
      return;
    }

    fetchPrescription(field_id, count, interpolate).then((data) => {
      if (data.features.length === 0) {
        console.warn("no features in the mower response");
        return;
      }

      let zones = new Map<number, Zone>();

      // Calculate area for each zone
      data.features.forEach((feature) => {
        const zone_id: number = feature.properties!.zone;
        const area = turfArea(feature);
        if (!zones.has(zone_id)) {
          const zone = {
            id: zone_id,
            rate: 0,
            raw_rate: "",
            area: area,
            color: feature.properties!.fill as string,
          };
          zones.set(zone_id, zone);
        } else {
          let zone = zones.get(zone_id)!;
          zone.area += area;
          zones.set(zone_id, zone);
        }
      });

      setZones(Array.from(zones.values()));

      (source as GeoJSONSource).setData(data);

      const camera = map.cameraForBounds(
        turfBBox(data) as [number, number, number, number])!;
      map.flyTo({
        ...camera,
        essential: true,
        padding: { top: 10, bottom: 10, left: 400, right: 10 },
      });
    }).catch((e) => {
      console.error(e);
      toast.error("API request failed:" + e.toString());
    }).finally(() => {
      setLoading(false);
    });

  }, [field_id, count, map]);

  return (
    <StyleScreen>
      {loading && (
        <LoadingLayout>
          <Spinner />
        </LoadingLayout>
      )}

      <GeoMap
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        onLoad={(e) => {
          setMap(e.target);
        }}
      >
        <Source
          id="prescription"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: [],
          }}>
          <Layer
            type="fill"
            id="yield"
            paint={{
              "fill-color": ["get", "fill"],
              "fill-opacity": 0.8,
            }}
          />
        </Source>
      </GeoMap>
      {zones.length > 0 && (
        <>
          <StyleModalWindow>
            <StyleModalHeader
              onClick={() => {
                setHidden((prev) => {
                  return !prev;
                })
              }}
            >
              <StyleModalHeaderText>{lang.prescription.settings} {
                hidden
                  ? <ExpandMore style={{
                    verticalAlign: "middle",
                    display: "inline-block",
                  }} />
                  : <ExpandLess style={{
                    verticalAlign: "middle",
                    display: "inline-block",
                  }} />
              }</StyleModalHeaderText>
            </StyleModalHeader>
            <StyleModalContainer hidden={hidden}>
              <CountSelect countState={[count, setCount]} />
              <StandardRateInput
                value={rawStandard}
                onChange={(value) => {
                  setRawStandard(value);
                  const parsed = parseFloat(value);

                  if (_.isNumber(parsed) && _.isFinite(parsed)) {
                    setStandard(parsed);
                  }
                }} />
              <Options
                zones={zones}
                standard={standard}
                setRate={(id: number, rate: string) => {
                  setZones((prev) => {
                    return prev.map((zone) => {
                      if (zone.id === id) {
                        zone.raw_rate = rate;
                        const parsed = parseFloat(zone.raw_rate);
                        if (_.isNumber(parsed) && _.isFinite(parsed)) {
                          zone.rate = parsed;
                        }
                      }
                      return zone;
                    });
                  })
                }} />
            </StyleModalContainer>
          </StyleModalWindow>

          <StyleActions>
            <Button
              kind="primary"
              onClick={() => {
                setExportDialogVisible(true);
              }}
            >{lang.prescription.download}</Button>
            <Button kind="outline" onClick={() => navigate(-1)}>
              {lang.prescription.cancel}
            </Button>
          </StyleActions>
          <DownloadDialog
            field_id={field_id}
            field_name={field_name}
            standard={standard}
            zones={zones}
            visible={isExportDialogVisible}
            onClose={() => {
              setExportDialogVisible(false);
            }}
          />
        </>
      )}
    </StyleScreen>
  );
};

const Screen: React.FC = () => {
  return <Prescription />;
};

export default Screen;
